

.title a {
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  font-family: Roboto, sans-serif;
  letter-spacing: 2.5px;
  color: #fff;
  line-height: 1.15;
  font-size: 2.2rem;
  text-shadow:
          1px 1px 1px #8F9D35,
          1px -1px 1px #8F9D35,
          -1px -1px 1px #8F9D35,
          -1px 1px 1px #8F9D35;
}

.title,
.description {
  text-align: center;
}

.description {
  /*text-shadow: 0 0 1px white;*/
  line-height: 1.5;
  font-size: 1rem;
  width: 80%;
  max-width: 800px;
}

.disclaimer {
  text-align: center;
  line-height: 1.5;
  width: 80%;
  max-width: 800px;
  font-size: 0.8rem;
  color: #8F9D35;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
}

.card {
  margin: 1rem;
  flex-basis: 45%;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #355464;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
}

.card:hover,
.card:focus,
.card:active {
  color: #d77d31;
  border-color: #d77d31;
}

.card h3 {
  /*margin: 0 0 1rem 0;*/
  margin: 0;
  font-size: 1.5rem;
  white-space: nowrap;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}
