@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Roboto:wght@400;500&display=swap');

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  letter-spacing: 0.05em;
  /*font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,*/
  /*  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;*/
  color: #355464;
  font-size: 0.9rem;
}

a {
  color: inherit;
  text-decoration: none;
}

.link {
  color: #0645AD;
  
}
.parag {
  font-size: 0.9rem
}
